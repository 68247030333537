import React, { useRef } from "react";
import { css } from '@emotion/core';
import Layout from '../components/layout';
import Card from '../components/card';
import Button from '../components/button';
import Input from '../components/input';
import AmountWrapper from '../components/amount-wrapper';
import { INPUT_PADDING } from '../common/constants';
import { getCart, getTotalPrice, getShippingCost } from '../common/cart';
import { isBrowser } from "../common/utils";

const cardPadding = '15px';
const cardWidth = `33% - ${cardPadding}`;

export default () => {
  const maxWidth = '1040px';
  const cart = getCart();
  const subtotal = getTotalPrice();
  const shippingCost = getShippingCost();
  const submitButtonRef = useRef(null);
  const state = {
    'payment-option': 'COD',
    subtotal,
    shipping: shippingCost,
    total: subtotal + shippingCost,
  };

  if (isBrowser() && cart.length === 0) window.location.href = '/';

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  function handleChange(e) {
    state[e.target.name] =  e.target.value;
  }

  function generateOrderBody() {
    const products = cart.reduce((str, product) => {
      const color = product.color ? `${product.color}, ` : '';
      return str + `${product.slug}: ${color}${product.qty}, ${product.price}\n`;
    }, '');

    return `
      Name: ${state.name}\n
      Mobile: ${state.mobile}\n
      Email: ${state.email}\n
      Street Address: ${state['street-address']}\n
      City: ${state.city}\n
      Province: ${state.province}\n
      Postal Code: ${state['postal-code']}\n
      ---\n
      Payment Option: ${state['payment-option']}\n
      ---\n
      ${products}
      ---\n
      Subtotal: ${state.subtotal}\n
      Shipping: ${state.shipping}\n
      Total: ${state.total}\n
    `;
  }

  function submitHandler(e) {
    e.preventDefault();
    const btn = submitButtonRef.current;
    btn.textContent = 'Placing your order...';
    btn.disabled = true;

    fetch('/.netlify/functions/sendgrid', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subject: `Order from ${state.name}`,
        message: generateOrderBody(),
      })
    })
      .then(() => {
        window.location.href = '/success?payment=' + state['payment-option'];
      })
      .catch(error => {
        alert(error);
        btn.disabled = false;
      })
    ;
  }

  return (
    <Layout title="Checkout | PasteWax">
      <form onSubmit={submitHandler}>
        <div css={css`
          display: flex;

          @media (max-width: ${maxWidth}) {
            flex-direction: column;
          }
        `}>
          <Card className="layout" css={css`
            margin: 0 ${cardPadding} ${cardPadding} 0;
            height: 100%;
            width: calc(${cardWidth});

            label {
              display: block;
              font-size: 0.8rem;
              margin: 1rem 0;
            }

            input {
              box-sizing: border-box;
              width: 100%;
            }

            p:last-of-type {
              margin-bottom: 0;
            }

            @media (max-width: ${maxWidth}) {
              width: unset;
            }
          `}>
            <h1 className="card-heading">Address</h1>

            <label htmlFor="name">
              First and last name*
              <Input type="text" name="name" placeholder="First and last name*" required onChange={handleChange} />
            </label>

            <label htmlFor="mobile">
              Mobile No.*
              <Input type="text" name="mobile" placeholder="Mobile No." required onChange={handleChange} />
            </label>

            <label htmlFor="email">
              Email
              <Input type="text" name="email" placeholder="Email" onChange={handleChange} />
            </label>

            <label htmlFor="street-address">
              Street address*
              <Input type="text" name="street-address" placeholder="Street address*" required onChange={handleChange} />
            </label>

            <label htmlFor="city">
              City*
              <Input type="text" name="city" placeholder="City*" required onChange={handleChange} />
            </label>

            <label htmlFor="province">
              Province*
              <select name="province" required defaultValue=""  onChange={handleChange}
                css={css`
                  padding: ${INPUT_PADDING};
                  width: 100%;
                `}
              >
                <option value="">Please select a Province*</option>
                <option value="Azad Kashmir">Azad Kashmir</option>
                <option value="Balochistan">Balochistan</option>
                <option value="Federally Administered Tribal Areas">Federally Administered Tribal Areas</option>
                <option value="Gilgit-Baltistan">Gilgit-Baltistan</option>
                <option value="Islamabad">Islamabad</option>
                <option value="Khyber Pakhtunkhwa">Khyber Pakhtunkhwa</option>
                <option value="Punjab">Punjab</option>
                <option value="Sindh">Sindh</option>
              </select>
            </label>

            <label htmlFor="postal-code">
              Postal code
              <Input type="number" name="postal-code" placeholder="Postal Code" onChange={handleChange} />
            </label>
          </Card>

          <div css={css`
            margin: 0 ${cardPadding} ${cardPadding} 0;
            width: calc(${cardWidth});

            @media (max-width: ${maxWidth}) {
              width: unset;
            }
          `}>
            <Card className="layout" css={css`
              margin-bottom: ${cardPadding};
            `}>
              <h1 className="card-heading">Payment Options</h1>
              <p>
                <label>
                  <input type="radio" name="payment-option" value="COD" defaultChecked onChange={handleChange} />
                  COD
                </label>
              </p>
              <p>
              <label><input type="radio" name="payment-option" value="Bank Transfer" onChange={handleChange} /> Bank Transfer</label>
              </p>
              <p>
                <label><input type="radio" name="payment-option" value="Easypaisa" onChange={handleChange} /> Easypaisa</label>
              </p>
            </Card>

            <Card className="layout" css={css`
              .product:first-of-type {
                padding-top: 0;
              }
              .product:last-of-type {
                border: none;
                padding-bottom: 0;
              }
            `}>
              <h1 className="card-heading">Cart</h1>
              {cart.map(product => (
                <div className="product" key={product.slug} css={css`
                  border-bottom: 1px solid #eee;
                  display: flex;
                  padding: 1em 0;
                `}>
                  <span css={css`
                    margin-right: 8px;
                  `}>
                    {product.title}{product.color ? ` - ${product.color}` : ''}
                    <input type="hidden" name={product.slug} value={product.qty + ', ' + product.price} />
                    <br />
                    PKR {product.price} x {product.qty}
                  </span>
                  <span css={css`
                    margin-left: auto;
                  `}>
                    PKR {product.price * product.qty}
                  </span>
                </div>
              ))}
            </Card>
          </div>

          <Card className="layout" css={css`
            height: 100%;
            width: calc(${cardWidth});

            @media (max-width: ${maxWidth}) {
              width: unset;
            }
          `}>
            <h1 className="card-heading">Payment</h1>
            <AmountWrapper css={css`padding-top: 0;`}>
              <span>Subtotal:</span>
              <span>PKR {subtotal}</span>
              <input type="hidden" name="total" value={subtotal} />
            </AmountWrapper>

            <AmountWrapper>
              <span>Shipping:</span>
              {/* <span>PKR {shippingCost}</span> */}
              <span>Free</span>
              <input type="hidden" name="shipping" value={shippingCost} />
            </AmountWrapper>

            <AmountWrapper css={css`
              border-top: 1px solid #eee;
              font-size: 1rem;
              font-weight: 500;
              margin-top: 1rem;
              padding: 1rem 0;
            `}>
              <span>Total:</span>
              <span>PKR {subtotal + shippingCost}</span>
            </AmountWrapper>

            <Button type="submit" ref={submitButtonRef}
              css={css`
                background: #fc0;
                border: none;
                cursor: pointer;
                font-family: "Open Sans", sans-serif;
                font-size: 0.9rem;
                font-weight: 600;
                color: #666;
                padding: 15px;
                width: 100%;
              `}
            >
              Place My Order
            </Button>
          </Card>
        </div>
      </form>
    </Layout>
  );
};
